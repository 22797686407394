import appUrl from "../constants/appUrl";
import { encryptionService } from "./encryption";

const axios = require("axios");
const axiosApi = axios.create();

// Request interceptor for API calls
axiosApi.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem("accessToken");
    config.headers = {
      Authorization: `Bearer ${access_token}`,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApi(originalRequest);
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    const { data } = await axios.post(appUrl.baseUrl + "auth/refreshToken", {
      refreshToken: refreshToken,
    });
    localStorage.setItem("accessToken", data.data.token);
    return data.accessToken;
  } else {
    return "";
  }
};

export { axiosApi };

const apiClient = axios.create({
  baseURL: `${appUrl.baseUrl}/api`, // Your base URL
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`, // Dynamic token
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    // console.log("Token before request:", token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.data) {
      const encryptedText = await encryptionService.encryptAES(
        JSON.stringify(config.data)
      );
      config.data = { Text: encryptedText };
    }
    // console.log("Request Config:", config);
    return config;
  },
  (error) => Promise.reject(error)
);

// apiClient.interceptors.response.use(
//   async (response) => {
//     if (response.data && response.data.Text) {
//       const decryptedText = await encryptionService.decryptAES(
//         response.data.Text
//       );
//       response.data = JSON.parse(decryptedText); // Parsed, decrypted response
//     }
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       // Clear relevant localStorage items
//       localStorage.clear();
//       localStorage.removeItem("login");
//       localStorage.removeItem("admin");
//       localStorage.removeItem("user");

//       // Redirect to login page
//       window.location.href = "./";
//     }
//     return Promise.reject(error);
//   }
// );

apiClient.interceptors.response.use(
  async (response) => {
    if (response.data?.Text) {
      const decryptedText = await encryptionService.decryptAES(response.data.Text);
      response.data = JSON.parse(decryptedText);
    }
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = "./"; 
    }

    console.error("API error occurred:", error);
    
    if (error.response?.data?.Text) {
      try {
        const decryptedErrorText = await encryptionService.decryptAES(error.response.data.Text);
        error.response.data = JSON.parse(decryptedErrorText);
      } catch (decryptionError) {
        console.error("Error decrypting the error response:", decryptionError);
      }
    }
    return Promise.reject(error);
  }
);



export default apiClient;
