import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import { FileUpload } from "primereact/fileupload";
import apiClient from "../../../../services/axios_api";

const AddEditPeoples = ({ onHide, editable, setRes, rowData, setDialog }) => {
  const [gateList, setGateList] = useState();
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState();
  const [deptList, setDeptList] = useState();
  const [imageBase64, setImageBase64] = useState("");
  const [fileName, setFileName] = useState("");

  const handleImageUpload = (event) => {
    const file = event.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result.split(",")[1];
      setImageBase64(base64Image);
      formik.setFieldValue("profileImageBase64", base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[3-9]\d{9}$/, "Invalid mobile number"),
    expiry: Yup.string().required("Expiry date is required"),
    name: Yup.string()
      .required("Name is Required")
      .max(30, "Name must be 30 characters"),
    gateIds: Yup.array().min(1, "Gate number is required").required("Required"),
    groupId: Yup.string()
      .required("Group is required")
      .max(30, "Title must be 30 characters"),
    cnicNumber: Yup.string()
      .required("CNIC is required")
      .min(13, "CNIC must be 13 characters")
      .max(13, "CNIC must be 13 characters"),
    profileImageBase64: Yup.string().required("Logo image is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      phoneNumber: "",
      name: "",
      gateIds: [],
      groupId: "",
      departmentId: "",
      email: "",
      cnicNumber: "",
      address: "",
      expiry: "",
      customerId: "",
      referenceUserId: "",
      profileImageBase64: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        if (!data.departmentId) {
          data.departmentId = null;
        }
        if (data.expiry) {
          data.expiry = moment(data.expiry).format("YYYY-MM-DD");
        }
        data.phoneNumber = `+92${data.phoneNumber}`;
        const payload = editable ? { ...data, id: rowData.id } : { ...data };

        const response = editable
          ? await apiClient.put("/People/update", payload)
          : await apiClient.post("/People/add", payload);

        setRes(response.data);
        if (response.data.responseCode === 200) {
          // Check if responseDescription is "Success"
          if (response.data.responseDescription === "Success") {
            toast.success(editable ? "People Updated Successfully" : "People Added Successfully");
          } else {
            toast.success(
              response.data.responseDescription ||
              (editable ? "People Updated Successfully" : "People Added Successfully")
            );
          }
          setDialog(false);
        } else {
          toast.error(
            response.data.responseDescription || "Unexpected response received."
          );
        }
        setDialog(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Operation failed, please try again";

        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  const GetGates = async (rowData) => {
    const userId = JSON.parse(localStorage.getItem("user"))?.id;
    try {
      const response = await apiClient.post("/Gates/list", {
        loggedInUserId: userId,
      });
      setGateList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const GetGroups = async (rowData) => {
    const userId = JSON.parse(localStorage.getItem("user"))?.id;
    try {
      const response = await apiClient.post("/Groups/list", {
        loggedInUserId: userId,
      });
      setGroupList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const GetDepartments = async (rowData) => {
    const userId = JSON.parse(localStorage.getItem("user"))?.id;
    try {
      const response = await apiClient.post("/Departments/list", {
        loggedInUserId: userId,
      });
      setDeptList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInitialImage = async (imageUrl) => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });

      const base64 = Buffer.from(response.data, "binary").toString("base64");

      formik.setFieldValue("profileImageBase64", base64);
      const extractedFileName = imageUrl.split("/").pop();
      setFileName(extractedFileName);
    } catch (error) {
      console.error("Error fetching and converting image:", error);
    }
  };
  

  useEffect(() => {
    if (editable && rowData) {
      const gateIds =
        rowData?.personAccessControls?.map((control) => control.gateId) || [];
      formik.setValues({
        name: rowData?.name || "",
        phoneNumber: rowData?.phoneNumber?.replace(/^\+92/, "") || "",
        gateIds: gateIds,
        groupId: rowData?.groupId || "",
        departmentId: rowData?.departmentId || "",
        address: rowData?.address || "",
        email: rowData?.email || "",
        cnicNumber: rowData?.cnicNumber || "",
        expiry: rowData?.personAccessControls?.[0]?.expiry
          ? moment(rowData.personAccessControls[0].expiry).toDate()
          : "",
        customerId: rowData?.customerId || "",
        referenceUserId: rowData?.referenceUserId || "",
      });
      if (rowData.profileImageUrl) {
        handleInitialImage(`${appUrl.baseUrl}${rowData.profileImageUrl}`);
      }
    }
  }, [editable, rowData]);

  useEffect(() => {
    GetGates();
    GetGroups();
    GetDepartments();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Name <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter name"
              keyfilter={/^[a-zA-Z\s]*$/}
              maxLength={30}
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Mobile Number <span className="Staric-Custom"> *</span>
            </label>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">+92</span>
              <InputText
                placeholder="Enter Mobile number"
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                maxLength={10}
                value={formik.values.phoneNumber}
                onChange={(e) => {
                  let input = e.target.value.replace(/[^\d]/g, "");
                  if (input.startsWith("0")) {
                    input = input.substring(1);
                  }
                  formik.setFieldValue("phoneNumber", input);
                }}
              />
            </div>
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Gate Number<span style={{ color: "red" }}>*</span>
            </label>

            <MultiSelect
              value={formik.values.gateIds}
              options={gateList}
              optionLabel="number"
              optionValue="id"
              placeholder="Select Gate Numbers"
              name="gateIds"
              onChange={(e) => formik.setFieldValue("gateIds", e.value)}
              display="chip"
            />
            {formik.touched.gateIds && formik.errors.gateIds ? (
              <div className="error">{formik.errors.gateIds}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Group<span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={formik.values.groupId}
              optionLabel="name"
              name="groupId"
              filter
              options={groupList || []}
              optionValue="id"
              emptyMessage="No options available"
              placeholder="Select Group"
              onChange={(e) => formik.setFieldValue("groupId", e.value)}
              // onChange={formik.handleChange}
            />
            {formik.touched.groupId && formik.errors.groupId ? (
              <div className="error">{formik.errors.groupId}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Gate Expiration Date<span style={{ color: "red" }}>*</span>
            </label>
            <Calendar
              id="expiry"
              value={formik.values.expiry}
              onChange={formik.handleChange}
              showIcon
              dateFormat="yy-mm-dd"
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.touched.expiry && formik.errors.expiry ? (
              <div className="error">{formik.errors.expiry}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              CNIC <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter CNIC number"
              id="cnicNumber"
              name="cnicNumber"
              type="text"
              value={formik.values.cnicNumber}
              maxLength={13}
              onChange={(e) =>
                formik.setFieldValue(
                  "cnicNumber",
                  e.target.value.replace(/[^0-9]/g, "")
                )
              }
            />
            {formik.touched.cnicNumber && formik.errors.cnicNumber ? (
              <div className="error">{formik.errors.cnicNumber}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Address{" "}
            </label>
            <InputText
              placeholder="Enter Address"
              maxLength={99}
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Email{" "}
            </label>
            <InputText
              placeholder="Enter Email"
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Department
            </label>
            <Dropdown
              value={formik.values.departmentId}
              optionLabel="name"
              filter
              name="departmentId"
              options={deptList || []}
              optionValue="id"
              placeholder="Select department"
              emptyMessage="No options available"
              onChange={(e) => formik.setFieldValue("departmentId", e.value)}
            />
          </div>

          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Customer Id
            </label>
            <InputText
              placeholder="Enter Customer Id"
              maxLength={6}
              id="customerId"
              name="customerId"
              value={formik.values.customerId}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Reference User Id (Roll no)
            </label>
            <InputText
              placeholder="Enter Reference User Id (Roll no)"
              maxLength={6}
              id="referenceUserId"
              name="referenceUserId"
              value={formik.values.referenceUserId}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field  md:col-6">
            <label htmlFor="profileImageBase64" style={{ fontWeight: "bold" }}>
              Upload Logo <span className="Staric-Custom"> *</span>
            </label>
            <FileUpload
              mode="basic"
              name="profileImageBase64"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={handleImageUpload}
              auto
            />
            {fileName && (
              <p
                style={{
                  marginBottom: "-8px",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                Selected file:
              </p>
            )}

            {formik.values.profileImageBase64 && (
              <div style={{ marginTop: "10px" }}>
                <img
                  src={`data:image/jpeg;base64,${formik.values.profileImageBase64}`}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                    border: "1px solid #ced4da",
                    borderRadius: "12px",
                  }}
                />
              </div>
            )}
            {formik.touched.profileImageBase64 &&
            formik.errors.profileImageBase64 ? (
              <div className="error">{formik.errors.profileImageBase64}</div>
            ) : null}
          </div>

          <div className="buttons-container">
            <div className="button-group">
              <Button
                className="custom-white"
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>

            <div className="button-group">
              <Button
                loading={loading}
                className="custom-btn"
                label={editable ? "Update" : "Add "}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditPeoples;
