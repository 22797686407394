import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import apiClient from "../../../../services/axios_api";

const AddEditGates = ({ onHide, editable, setRes, setDialog, rowData }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    number: Yup.string()
      .required("Gate number is required")
      .max(5, "Gate number must be 5 characters")
      .test(
        "not-zero",
        "Gate number must not be zero",
        (value) => value !== "0"
      ),
    description: Yup.string()
      .required("Description is required")
      .max(30, "Description must be 30 characters"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      number: "",
      description: "",
      supportsOfflineMode: false,
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          number: data.number,
          description: data.description,
          supportsOfflineMode: data.supportsOfflineMode,
          ...(editable && { id: rowData.id }),
        };

        const response = editable
          ? await apiClient.put("/Gates/update", payload)
          : await apiClient.post("/Gates/add", payload);

        setRes(response.data);
        toast.success(
          editable ? "Gate Updated Successfully" : "Gate Added Successfully"
        );
        setDialog(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Operation failed, please try again";

        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("description", rowData?.description);
      formik.setFieldValue("number", rowData?.number);
      formik.setFieldValue(
        "supportsOfflineMode",
        rowData?.supportsOfflineMode || false
      );
    }
  }, [editable, rowData]);

  return (
    <>
      {" "}
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-12 flex align-items-center ">
            <Checkbox
              inputId="supportsOfflineMode"
              name="supportsOfflineMode"
              checked={formik.values.supportsOfflineMode}
              onChange={(e) =>
                formik.setFieldValue("supportsOfflineMode", e.checked)
              }
              style={{ marginRight: "10px" }}
            />
            Select this option to display the gate as offline. Only those with
            <br />
            an offline QR code pass will be allowed entry at this gate.{" "}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Gate No <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter number"
              maxLength={3}
              id="number"
              name="number"
              type="text"
              value={formik.values.number}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("number", sanitizedValue);
              }}
            />
            {formik.touched.number && formik.errors.number ? (
              <div className="error">{formik.errors.number}</div>
            ) : null}
          </div>
          <div className="field  md:col-12">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Description <span className="Staric-Custom"> *</span>
            </label>
            <InputTextarea
              placeholder="Enter description"
              maxLength={50}
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              rows={3}
              cols={30}
              className={
                formik.touched.description && formik.errors.description
                  ? "p-invalid"
                  : ""
              }
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="error">{formik.errors.description}</div>
            ) : null}
          </div>
          <div className="buttons-container">
            <div className="button-group">
              <Button
                className="custom-white"
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>

            <div className="button-group">
              <Button
                loading={loading}
                className="custom-btn"
                label={editable ? "Update" : "Add "}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditGates;
