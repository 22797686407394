import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiClient from "../../../../services/axios_api";

const UpdateRequestStatus = ({ onHide, rowData, setRes, setDialog }) => {
  const [loading, setLoading] = useState(false);

  const statusOptions = [
    { label: "Approve", value: true },
    { label: "Reject", value: false },
  ];

  const validationSchema = Yup.object({
    isApproved: Yup.boolean().required("Status is required"),
    remarks: Yup.string().test(
      "remarks-required",
      "Remarks are required when rejecting",
      function (value) {
        return (
          this.parent.isApproved !== false || (value && value.trim() !== "")
        );
      }
    ),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      isApproved: "",
      remarks: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          id: rowData.id,
          isApproved: data.isApproved,
          remarks: data.remarks,
        };

        const response = await apiClient.put(
          "/RegistrationRequests/update/status",
          payload
        );

        setRes(response.data);
        toast.success("Request status updated successfully");

        setDialog(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Operation failed, please try again";

        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-12">
            <label htmlFor="isApproved" style={{ fontWeight: "bold" }}>
              Status <span className="Staric-Custom"> *</span>
            </label>
            <Dropdown
              id="isApproved"
              value={formik.values.isApproved}
              name="isApproved"
              options={statusOptions}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue("remarks", "");
              }}
              placeholder="Select Status"
            />
            {formik.touched.isApproved && formik.errors.isApproved ? (
              <div className="error">{formik.errors.isApproved}</div>
            ) : null}
          </div>

          {formik.values.isApproved === false && (
            <div className="field md:col-12">
              <label htmlFor="remarks" style={{ fontWeight: "bold" }}>
                Remarks <span className="Staric-Custom"> *</span>
              </label>
              <InputText
                id="remarks"
                name="remarks"
                type="text"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                disabled={formik.values.isApproved !== false}
              />
              {formik.touched.remarks && formik.errors.remarks ? (
                <div className="error">{formik.errors.remarks}</div>
              ) : null}
            </div>
          )}

          <div className="buttons-container">
            <div className="button-group">
              <Button
                className="custom-white"
                label="Cancel"
                type="button"
                disabled={loading}
                onClick={onHide}
              />
            </div>

            <div className="button-group">
              <Button
                loading={loading}
                className="custom-btn"
                label="Update"
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateRequestStatus;
