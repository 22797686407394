import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditOrganization from "../components/add_edit_organization";
import appUrl from "../../../../constants/appUrl";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import deleteIcon from "../../../../../assets/icons/trash.png";
import {
  formatDate,
  emailsRenderTextWithTooltip,
  renderTextWithTooltip,
  formatTo12Hour,
} from "../../../components/helper";
import * as XLSX from "xlsx";
import CustomImagePreview from "../../../components/custom_imagepreview";
import apiClient from "../../../../services/axios_api";

const Organization = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [listData, setListData] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const GetOrganizations = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get("/Organizations/all");
      setListData(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onHide = () => {
    setDialog(false);
  };

  const confirm = (rowData) => {
    confirmDialog({
      header: <div className="custom-header">Delete Organization</div>,
      icon: "pi pi-info-circle",
      message: (
        <strong>Are you sure you want to delete this Organization?</strong>
      ),
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };

  const accept = async (rowData) => {
    try {
      const response = await apiClient.post("/Organizations/delete", { id: rowData?.id });
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Failed to delete");
    }
  };
  

  const reject = (rowData) => {
    return;
  };

  const actionTemplate = (rowData) => (
    <div className="action-buttons">
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editAction(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>

      <Button
        className="custom-btn-delete"
        onClick={() => {
          confirm(rowData);
        }}
        disabled={listData.length === 1}
      >
        <img
          src={deleteIcon}
          alt="Delete"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );

  const exportCSV = () => {
    const exportData = listData.map((item) => ({
      "Organization Name": item.name,
      "Mobile Number": item.phoneNumber,
      Address: item.address,
      Email: item.email,
      "Modified Date": formatTo12Hour(item.modifiedDate),
    }));

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Define column widths (all set to 120 pixels)
    worksheet["!cols"] = [
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
    ];

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Organization Data");

    // Write the workbook to file
    XLSX.writeFile(workbook, "organization_data.xlsx");
  };

  useEffect(() => {
    GetOrganizations();
  }, [res]);

  return (
    <>
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Organization" : "Add Organization"}
        className="dialog-size"
      >
        <AddEditOrganization
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive gap-3">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={listData}
          scrollable
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={["number"]}
          className="custom-data-table"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column field="name" header="Organization Name" />
          <Column field="phoneNumber" header="Mobile Number" />
          <Column
            field="address"
            header="Address"
            body={(rowData) =>
              renderTextWithTooltip(rowData, "address", "bottom")
            }
          />
          <Column
            field="email"
            header="Email"
            body={(rowData) =>
              emailsRenderTextWithTooltip(rowData, "email", "bottom")
            }
          />
          <Column
            field="modifiedDate"
            header="Date"
            body={(rowData) => formatDate(rowData.modifiedDate)}
          />
          <Column
            header="Logo"
            body={(rowData) => (
              <CustomImagePreview
                src={`${appUrl.baseUrl}${rowData.logoUrl}`}
                alt="Profile"
              />
            )}
          />
          <Column field="" header="Action" body={actionTemplate} />
        </DataTable>
      </div>
    </>
  );
};

export default Organization;
