// import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "./redux/auth_slice/login_user_slice";

// const user = localStorage.getItem("user");

// const initialState = {};

// const store = configureStore({
//   reducer: {
//     auth: authReducer,
//   },
//   preloadedState: initialState,
// });

// export default store;


import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./redux/auth_slice/login_user_slice";

const user = JSON.parse(localStorage.getItem("user"));

const preloadedState = {
  auth: {
    user: user || null,
    accessToken: null,
    loading: false,
    error: null,
  },
};

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  preloadedState,
});

export default store;
