import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiClient from "../../../../services/axios_api";

const AddEditGroups = ({ onHide, editable, setRes, setDialog, rowData }) => {
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(30, "Name must be 30 characters"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
    },
    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          name: data.name,
          ...(editable && { id: rowData.id }),
        };

        const response = editable
          ? await apiClient.put("/Groups/update", payload)
          : await apiClient.post("/Groups/add", payload);

        setRes(response.data);
        toast.success(
          editable ? "Group Updated Successfully" : "Group Added Successfully"
        );
        setDialog(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Operation failed, please try again";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("name", rowData?.name);
    }
  }, [editable, rowData]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-fluid formgrid grid">
        <div className="field  md:col-6">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Name <span className="Staric-Custom"> *</span>
          </label>
          <InputText
            placeholder="Enter name"
            maxLength={30}
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
              formik.setFieldValue("name", sanitizedValue);
            }}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null}
        </div>

        <div className="buttons-container">
          <div className="button-group">
            <Button
              className="custom-white"
              label="Cancel"
              type="button"
              onClick={onHide}
            />
          </div>

          <div className="button-group">
            <Button
              loading={loading}
              className="custom-btn"
              label={editable ? "Update" : "Add "}
              type="submit"
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddEditGroups;
