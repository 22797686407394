const fetchWith401Handling = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    if (response.status === 401 || response.status === 404) {
      window.localStorage.clear();
      localStorage.removeItem("login");
      localStorage.removeItem("admin");
      localStorage.removeItem("user");

      window.location.href = "./";
      return;
    }

    return response;
  } catch (error) {
    console.error("Error in API request:", error);
    return null;
  }
};

export default fetchWith401Handling;
