import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditGroups from "../components/add_edit_groups";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import { formatDate, formatTo12Hour } from "../../../components/helper";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import * as XLSX from "xlsx";
import deleteIcon from "../../../../../assets/icons/trash.png";
import apiClient from "../../../../services/axios_api";

const Groups = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState();
  const [res, setRes] = useState(false);
  const dt = useRef(null);
  const [editable, setEditable] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const GetGroupsList = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get("/Groups/all");
      setGroupList(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };

  const onHide = () => {
    setDialog(false);
  };
  const actionTemplate = (rowData) => (
    <div className="action-buttons">
      <Button className="custom-btn-edit" onClick={() => editAction(rowData)}>
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
      <Button
        className="custom-btn-delete"
        onClick={() => {
          confirm(rowData);
        }}
        disabled={groupList.length === 1}
      >
        <img
          src={deleteIcon}
          alt="Delete"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );
  const confirm = (rowData) => {
    confirmDialog({
      header: <div className="custom-header">Delete Group</div>,
      icon: "pi pi-info-circle",
      message: <strong>Are you sure you want to delete this Group?</strong>,
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const accept = async (rowData) => {
    try {
      const response = await apiClient.post("/Groups/delete", { id: rowData?.id });
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Failed to delete");
    }
  };

  const reject = (rowData) => {
    return;
  };

  const exportCSV = () => {
    const exportData = groupList.map((item) => ({
      "Gate Name": item.name,
      "Modified Date": formatTo12Hour(item.modifiedDate),
    }));

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Define column widths (all set to 120 pixels)
    worksheet["!cols"] = [{ wpx: 120 }, { wpx: 120 }];

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Groups Data");

    // Write the workbook to file
    XLSX.writeFile(workbook, "groups_data.xlsx");
  };

  useEffect(() => {
    GetGroupsList();
  }, [res]);

  const columnsData = [
    {
      header: "Group Name",
      field: "name",
    },
    {
      field: "modifiedDate",
      header: "Date",
      body: (rowData) => formatDate(rowData.modifiedDate),
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Group" : "Add Group"}
        className="dialog-size"
      >
        <AddEditGroups
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive gap-3">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>
      <div className="card">
        {loading && <Loader />}

        <DataTable
          value={groupList}
          scrollable
          scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={["name"]}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default Groups;
