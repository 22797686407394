import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import appUrl from "../../constants/appUrl";

// Thunk for handling login
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${appUrl.baseUrl}${appUrl.authPath}`, credentials);
    if (response.status === 200 && response.data?.accessToken) {
      return response.data;
    } else {
      return rejectWithValue(response.data?.responseDescription || 'Invalid credentials');
    }
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Network error');
  }
});



const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    accessToken: null,
    loading: false,
    error: null,
    passwordUpdated: false, // Track if password update was successful
  },
  reducers: {
    logout(state) {
      state.user = null;
      state.accessToken = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('login');
    },
    clearUpdateState(state) {
      state.passwordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;

        // Save user info and token to localStorage
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        localStorage.setItem('token', action.payload.accessToken);

        if (action.payload.user.mustChangePassword) {
          localStorage.removeItem('login');
        } else {
          localStorage.setItem('login', true);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { logout, clearUpdateState } = authSlice.actions;

export default authSlice.reducer;
