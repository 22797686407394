import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import apiClient from "../../../../services/axios_api";

const AddEditAdminControl = ({
  onHide,
  editable,
  setRes,
  setDialog,
  rowData,
}) => {
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [profileImageBase64, setProfileImageBase64] = useState("");

  // Convert file to base64
  const handleImageUpload = (event) => {
    const file = event.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result.split(",")[1];
      setProfileImageBase64(base64Image);
      formik.setFieldValue("profileImageBase64", base64Image);
    };

    if (file) {
      reader.readAsDataURL(file); // Convert file to base64
    }
  };
  const validationSchema = Yup.object({
    organizationId: Yup.number().required("Required"),

    name: Yup.string()
      .required("Required")
      .matches(
        /^[A-Za-z\s]+$/,
        "Name must contain only alphabetic characters and spaces"
      )
      .max(30, "Name must be at most 30 characters long"),
    cnicNumber: Yup.string()
      .required("Required")
      .matches(/^\d{13}$/, "CNIC number must be exactly 13 digits"),
    address: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[3-9]\d{9}$/, "Invalid mobile number"),
    editable: Yup.boolean().default(false),
    profileImageBase64: Yup.string().required("Profile image is required"),
    password: editable
      ? Yup.string().notRequired()
      : Yup.string()
          .required("Password is required")
          .matches(
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}/,
            "Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol."
          ),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      organizationId: "",
      branchId: null,
      name: "",
      cnicNumber: "",
      address: "",
      email: "",
      phoneNumber: "",
      password: "",
      profileImageBase64: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          organizationId: data.organizationId,
          branchId: data.branchId,
          name: data.name,
          cnicNumber: data.cnicNumber,
          address: data.address,
          email: data.email,
          phoneNumber: `+92${data.phoneNumber}`,
          profileImageBase64: profileImageBase64,
          password: data.password,
          ...(editable && { id: rowData.id }),
        };

        const response = editable
          ? await apiClient.put("/AdminUser/update", payload)
          : await apiClient.post("/AdminUser/add", payload);

        setRes(response.data);
        toast.success(
          editable
            ? "Organization Admin Updated Successfully"
            : "Organization Admin Added Successfully"
        );
        setDialog(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Operation failed, please try again";

        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  const getOrganizations = async (rowData) => {
    try {
      const response = await apiClient.get("/Organizations/list");
      setOrganizations(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const handleInitialImage = async (imageUrl) => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });
      const base64 = Buffer.from(response.data, "binary").toString("base64");
      formik.setFieldValue("profileImageBase64", base64);
      const extractedFileName = imageUrl.split("/").pop();
      setFileName(extractedFileName);
    } catch (error) {
      console.error("Error fetching and converting image:", error);
    }
  };

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("name", rowData?.name);
      const organizationId =
        rowData?.accessControls?.[0]?.organization?.id || "";
      formik.setFieldValue("organizationId", organizationId);
      formik.setFieldValue("cnicNumber", rowData?.cnicNumber);
      formik.setFieldValue("address", rowData?.address);
      formik.setFieldValue("email", rowData?.email);
      formik.setFieldValue(
        "phoneNumber",
        rowData?.phoneNumber?.replace(/^\+92/, "")
      );

      if (rowData.profileImageUrl) {
        handleInitialImage(`${appUrl.baseUrl}${rowData.profileImageUrl}`);
      }
    }
  }, [editable, rowData]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Organization <span className="Staric-Custom"> *</span>
            </label>
            <Dropdown
              value={formik.values.organizationId}
              optionLabel="name"
              name="organizationId"
              options={organizations}
              optionValue="id"
              placeholder="Select Organization"
              filter
              onChange={formik.handleChange}
            />
            {formik.touched.organizationId && formik.errors.organizationId ? (
              <div className="error">{formik.errors.organizationId}</div>
            ) : null}
          </div>
          {/* <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Branches
            </label>
            <Dropdown
              value={formik.values.branchId}
              optionLabel="name"
              name="branchId"
              options={branches}
              optionValue="id"
              placeholder="Select"
              filter
              onChange={formik.handleChange}
            />
            {formik.touched.branchId && formik.errors.branchId ? (
              <div className="error">{formik.errors.branchId}</div>
            ) : null}
          </div> */}
          <div className="field  md:col-6">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Name <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter name"
              keyfilter={/^[a-zA-Z\s]*$/}
              maxLength={30}
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="cnicNumber" style={{ fontWeight: "bold" }}>
              CNIC Number <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter CNIC number"
              maxLength={13}
              id="cnicNumber"
              name="cnicNumber"
              type="text"
              value={formik.values.cnicNumber}
              onChange={(e) =>
                formik.setFieldValue(
                  "cnicNumber",
                  e.target.value.replace(/[^0-9]/g, "")
                )
              }
            />
            {formik.touched.cnicNumber && formik.errors.cnicNumber ? (
              <div className="error">{formik.errors.cnicNumber}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="address" style={{ fontWeight: "bold" }}>
              Address <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter address"
              maxLength={99}
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="email" style={{ fontWeight: "bold" }}>
              Email <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter email"
              id="email"
              name="email"
              type="text"
              // autoComplete="new-email"
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="phoneNumber" style={{ fontWeight: "bold" }}>
              Mobile Number <span className="Staric-Custom"> *</span>
            </label>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">+92</span>
              <InputText
                placeholder="Enter mobile number"
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                maxLength={10}
                value={formik.values.phoneNumber}
                onChange={(e) => {
                  let input = e.target.value.replace(/[^\d]/g, "");
                  if (input.startsWith("0")) {
                    input = input.substring(1);
                  }
                  formik.setFieldValue("phoneNumber", input);
                }}
              />
            </div>
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>

          {!editable && (
            <div className="field  md:col-6 password-icon">
              <label htmlFor="password" style={{ fontWeight: "bold" }}>
                Password <span className="Staric-Custom"> *</span>
              </label>

              <Password
                // className="auth-welcome"
                placeholder="Enter password"
                type="text"
                id="password"
                name="password"
                maxLength={30}
                value={formik.values.password}
                toggleMask
                onChange={formik.handleChange}
                feedback={false}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
          )}

          <div className="field  md:col-6">
            <label htmlFor="profileImage" style={{ fontWeight: "bold" }}>
              Upload Logo <span className="Staric-Custom"> *</span>
            </label>
            <FileUpload
              mode="basic"
              name="profileImage"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={handleImageUpload}
              auto
            />
            {/* {fileName && <p>Selected file: {fileName}</p>} */}
            {fileName && (
              <p
                style={{
                  marginBottom: "-8px",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                Selected file:
              </p>
            )}

            {formik.values.profileImageBase64 && (
              <div style={{ marginTop: "10px" }}>
                <img
                  src={`data:image/jpeg;base64,${formik.values.profileImageBase64}`}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                    border: "1px solid #ced4da",
                    borderRadius: "12px",
                  }}
                />
              </div>
            )}
            {formik.touched.profileImageBase64 &&
            formik.errors.profileImageBase64 ? (
              <div className="error">{formik.errors.profileImageBase64}</div>
            ) : null}
          </div>
          <div className="buttons-container">
            <div className="button-group">
              <Button
                className="custom-white"
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>

            <div className="button-group">
              <Button
                loading={loading}
                className="custom-btn"
                label={editable ? "Update" : "Add"}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditAdminControl;
