import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import appUrl from "../../../../constants/appUrl";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import EditRequestStatus from "../components/edit_registration-requests";
import { Tag } from "primereact/tag";
import { TabMenu } from "primereact/tabmenu";
import {
  emailsRenderTextWithTooltip,
  renderTextWithTooltip,
} from "../../../components/helper";
import Loader from "../../../components/loader";
import * as XLSX from "xlsx";
import apiClient from "../../../../services/axios_api";

const RegistrationRequests = () => {
  const [rowselect, setRowselect] = useState(null);
  const [editDialog, setEditDialog] = useState();
  const [listData, setListData] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editableTwo, setEditableTwo] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  // const GetPendingRequests = async () => {
  //   try {
  //     setLoading(true);
  //     const encryptedStatus = await encryptionService.encryptAES(
  //       JSON.stringify({ status: "pending" })
  //     );
  //     const response = await fetchWith401Handling(
  //       `${appUrl.baseUrl}/api/RegistrationRequests/all`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           Text: encryptedStatus,
  //         }),
  //       }
  //     );
  //     const responseData = await response.json();

  //     if (responseData.Text) {
  //       const decryptedText = await encryptionService.decryptAES(responseData.Text);
  //       const decryptedData = JSON.parse(decryptedText);
  //       // console.log(decryptedData, 'decryptedData')

  //       setListData(decryptedData?.data);
  //     } else {
  //       console.error("Encrypted text missing in response");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const GetRejectedRequests = async () => {
  //   try {
  //     setLoading(true);
  //     const encryptedStatus = await encryptionService.encryptAES(
  //       JSON.stringify({ status: "rejected" })
  //     );

  //     const response = await fetchWith401Handling(
  //       `${appUrl.baseUrl}/api/RegistrationRequests/all`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           Text: encryptedStatus,
  //         }),
  //       }
  //     );

  //     const responseData = await response.json();

  //     if (responseData.Text) {
  //       const decryptedText = await encryptionService.decryptAES(responseData.Text);
  //       const decryptedData = JSON.parse(decryptedText);
  //       // console.log(decryptedData, 'decryptedData')

  //       setRejectedList(decryptedData?.data || []);
  //     } else {
  //       console.error("Encrypted text missing in response");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const GetApprovedRequests = async () => {
  //   try {
  //     setLoading(true);
  //     const encryptedStatus = await encryptionService.encryptAES(
  //       JSON.stringify({ status: "approved" })
  //     );

  //     const response = await fetchWith401Handling(
  //       `${appUrl.baseUrl}/api/RegistrationRequests/all`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           Text: encryptedStatus,
  //         }),
  //       }
  //     );

  //     const responseData = await response.json();

  //     if (responseData.Text) {
  //       const decryptedText = await encryptionService.decryptAES(responseData.Text);
  //       const decryptedData = JSON.parse(decryptedText);
  //       // console.log(decryptedData, 'decryptedData')

  //       setApprovedList(decryptedData?.data || []);
  //     } else {
  //       console.error("Encrypted text missing in response");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   GetPendingRequests();
  //   GetApprovedRequests();
  //   GetRejectedRequests();
  // }, [res]);

  const GetPendingRequests = async (rowData) => {
    try {
      const response = await apiClient.post("/RegistrationRequests/all", {
        status: "pending",
      });
      setListData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const GetRejectedRequests = async () => {
    try {
      const response = await apiClient.post("/RegistrationRequests/all", {
        status: "rejected",
      });
      setRejectedList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const GetApprovedRequests = async () => {
    try {
      const response = await apiClient.post("/RegistrationRequests/all", {
        status: "approved",
      });
      setApprovedList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to fetch all requests in parallel
  const fetchAllRequests = async () => {
    setLoading(true);
    try {
      await Promise.all([
        GetPendingRequests(),
        GetApprovedRequests(),
        GetRejectedRequests(),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all requests on component mount or when `res` changes
  useEffect(() => {
    fetchAllRequests();
  }, [res]);

  const onHideTwo = () => {
    setEditDialog(false);
  };

  const editActionTwo = (rowData) => {
    setEditableTwo(true);
    setRowselect(rowData);
    setEditDialog(true);
  };

  const actionTemplate = (rowData) => (
    <div className="action-buttons">
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editActionTwo(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );

  const exportCSV = () => {
    const exportData = (
      activeTab === 0 ? listData : activeTab === 1 ? approvedList : rejectedList
    ).map((item) => ({
      "User Name": item.name,
      "Mobile Number": item.phoneNumber,
      Email: item.email,
      Address: item.address,
      "Organization Name": item.organizationName,
      "Organization Email": item.organizationEmail,
      "Organization Phone": item.organizationPhoneNumber,
      Status: item.status,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    worksheet["!cols"] = [
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, "Admin Data");

    XLSX.writeFile(workbook, "admin_data.xlsx");
  };

  const formattedStatus = (rowData) => {
    return (
      <div>
        <Tag
          style={{
            width: "100px",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            backgroundColor:
              rowData.status === "Approved"
                ? "#22C55E"
                : rowData.status === "Rejected"
                ? "#EF4444"
                : "#6366F1",
          }}
        >
          {rowData.status === "Pending"
            ? "Pending"
            : rowData.status === "Approved"
            ? "Approved"
            : "Rejected"}
        </Tag>
      </div>
    );
  };

  // Tab items
  const items = [
    { label: "Pending" },
    { label: "Approved" },
    { label: "Rejected" },
  ];

  // Handle tab change
  const handleTabChange = (e) => {
    setActiveTab(e.index); // Update active tab index
  };

  return (
    <>
      <Dialog
        visible={editDialog}
        onHide={onHideTwo}
        header={"Update Registration Request Status"}
        className="dialog-size"
      >
        <EditRequestStatus
          dialog={editDialog}
          editable={editableTwo}
          rowData={rowselect}
          setDialog={setEditDialog}
          onHide={onHideTwo}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive gap-3">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />
        </div>
      </div>

      <div className="card">
        <TabMenu
          model={items}
          activeIndex={activeTab}
          onTabChange={handleTabChange}
        />
        {loading && <Loader />}

        <DataTable
          ref={dt}
          value={
            activeTab === 0
              ? listData
              : activeTab === 1
              ? approvedList
              : rejectedList
          }
          paginator
          rows={10}
          responsiveLayout="scroll"
          emptyMessage="No records found"
          filters={filters}
          globalFilterFields={["name", "status"]}
          stripedRows
          className="custom-data-table"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            field="name"
            header="User Name"
            body={(rowData) => renderTextWithTooltip(rowData, "name", "bottom")}
          />
          <Column field="phoneNumber" header="Mobile Number" />
          <Column
            field="address"
            header="Address"
            body={(rowData) =>
              renderTextWithTooltip(rowData, "address", "bottom")
            }
          />
          <Column
            field="email"
            header="Email"
            body={(rowData) =>
              emailsRenderTextWithTooltip(rowData, "email", "bottom")
            }
          />
          <Column
            field="organizationName"
            header="Organization Name"
            body={(rowData) =>
              renderTextWithTooltip(rowData, "organizationName", "bottom")
            }
          />
          <Column
            field="organizationEmail"
            header="Organization Email"
            body={(rowData) =>
              emailsRenderTextWithTooltip(
                rowData,
                "organizationEmail",
                "bottom"
              )
            }
          />
          {/* <Column field="organizationPhoneNumber" header="Organization Phone" />
          {activeTab === 2 && (
            <Column
              field="remarks"
              header="Remarks"
            />
          )} */}
          <Column
            header="Organization Logo"
            body={(rowData) => (
              <img
                src={`${appUrl.baseUrl}${rowData.logoUrl}`}
                alt="Profile"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            )}
          />

          <Column field="status" header="Status" body={formattedStatus} />
          {activeTab === 0 && (
            <Column field="" header="Action" body={actionTemplate} />
          )}
        </DataTable>
      </div>
    </>
  );
};

export default RegistrationRequests;
