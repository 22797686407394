import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import appUrl from "../../../../constants/appUrl";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../components/loader";
import apiClient from "../../../../services/axios_api";

const QrSetting = () => {
  const [qrSettingType, setQrSettingType] = useState("");
  const [timeBasedExpirationDate, setTimeBasedExpirationDate] = useState(null);
  const [scanBasedExpirationDate, setScanBasedExpirationDate] = useState(null);
  const [scanLimit, setScanLimit] = useState(1);
  const [existingSetting, setExistingSetting] = useState(null);
  const apiUrl = `${appUrl.baseUrl}/api/QRCodeSettings`;
  const user = useSelector((state) => state.auth.user);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [timeError, setTimeError] = useState("");
  const [scanError, setScanError] = useState("");
  const [settingTypeError, setSettingTypeError] = useState("");
  const [modifiedDate, setModifiedDate] = useState(null);

  useEffect(() => {
    const fetchExistingSetting = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get(`${apiUrl}/all`);

        const setting = response.data?.data?.[0];

        if (setting) {
          setExistingSetting(setting);
          setQrSettingType(setting.settingType.toLowerCase());
          setModifiedDate(new Date(setting.modifiedDate));

          if (
            setting.settingType.toLowerCase() === "time-based" &&
            setting.expiryDate
          ) {
            setTimeBasedExpirationDate(new Date(setting.expiryDate));
          } else if (
            setting.settingType.toLowerCase() === "scan-based" &&
            setting.expiryDate
          ) {
            setScanBasedExpirationDate(new Date(setting.expiryDate));
          }

          setScanLimit(setting.scanLimit || 1);
        }
      } catch (error) {
        toast.error("Error fetching existing QR Setting.");
      } finally {
        setLoading(false);
      }
    };

    fetchExistingSetting();
  }, [token]);

  const handleTypeChange = (type) => {
    setQrSettingType(type);
    setTimeError("");
    setScanError("");
    setSettingTypeError("");
  };

  const handleSubmit = async () => {
    let valid = true;

    if (!qrSettingType) {
      setSettingTypeError("Please select one QR setting option.");
      valid = false;
    }

    if (qrSettingType === "time-based") {
      if (!timeBasedExpirationDate) {
        setTimeError(
          "Please select an expiration date for Time-Based setting."
        );
        valid = false;
      } else {
        setTimeError("");
      }
    }

    if (qrSettingType === "scan-based") {
      if (!scanBasedExpirationDate) {
        setScanError(
          "Please select an expiration date for Scan-Based setting."
        );
        valid = false;
      } else {
        setScanError("");
      }
    }

    if (!valid) return;

    const payload = {
      settingType: qrSettingType === "time-based" ? "Time-Based" : "Scan-Based",
      organizationId: user?.accessControls[0]?.organizationId,
      // scanLimit: qrSettingType === "scan-based" ? scanLimit : 0,
      scanLimit: qrSettingType === "scan-based" ? Number(scanLimit) : 0,
      expiryDate:
        qrSettingType === "time-based"
          ? moment(timeBasedExpirationDate).format("YYYY-MM-DD")
          : moment(scanBasedExpirationDate).format("YYYY-MM-DD"),
      createdAt: existingSetting
        ? existingSetting.createdDate
        : new Date().toISOString(),
    };
    setLoading(true);

    try {
      if (existingSetting) {
        await apiClient.put(`${apiUrl}/update`, {
          ...payload,
          id: existingSetting.id,
        });
        toast.success("QR Setting updated successfully!");
      } else {
        await apiClient.post(`${apiUrl}/add`, payload);
        toast.success("QR Setting saved successfully!");
      }
    } catch (error) {
      toast.error("Error saving QR Setting.");
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      {loading && <Loader />}
      <div className="qr-setting-container">
        <div className="qr-setting-card">
          <h6>
            Set the Offline QR setting according to your preference by choosing
            one option from the checkboxes below.
          </h6>
          <br />

          <div className="field md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Date of Last QR Code Scan Settings Update
            </label>
            <br />
            {modifiedDate ? (
              <Calendar
                id="modifiedDate"
                value={modifiedDate}
                dateFormat="yy-mm-dd"
                showIcon
                disabled
              />
            ) : (
              ""
            )}
          </div>
          <div className="checkbox-group">
            <Checkbox
              inputId="timeBased"
              value="time-based"
              onChange={(e) => handleTypeChange(e.value)}
              checked={qrSettingType === "time-based"}
            />
            <label htmlFor="timeBased">Time-Based</label>
          </div>

          {qrSettingType === "time-based" && (
            <div className="field md:col-6">
              <label className="mb-2" style={{ fontWeight: "bold" }}>
                Expiration Date<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <Calendar
                id="timeBasedExpirationDate"
                value={timeBasedExpirationDate}
                onChange={(e) => {
                  setTimeBasedExpirationDate(e.value);
                  if (e.value) {
                    setTimeError("");
                  }
                }}
                dateFormat="yy-mm-dd"
                showIcon
                minDate={new Date()}
                placeholder="Select Date"
              />
              <br />
              {timeError && <small className="error">{timeError}</small>}{" "}
            </div>
          )}

          <div className="checkbox-group">
            <Checkbox
              inputId="scanBased"
              value="scan-based"
              onChange={(e) => handleTypeChange(e.value)}
              checked={qrSettingType === "scan-based"}
            />
            <label htmlFor="scanBased">Scan-Based</label>
          </div>

          {qrSettingType === "scan-based" && (
            <>
              <div className="field md:col-6">
                <label className="mb-2" style={{ fontWeight: "bold" }}>
                  Scan Limit<span style={{ color: "red" }}>*</span>
                </label>
                <br />

                <InputText
                  id="scanLimit"
                  value={scanLimit}
                  onChange={(e) => {
                    const value = e.target.value;
                    if ((value >= 1 && value <= 99) || value === "") {
                      setScanLimit(value);
                    }
                  }}
                  type="number"
                  min="1"
                  max="99"
                  maxLength="2"
                  placeholder="Enter number of scans"
                  className="input-number"
                  style={{ width: "225px" }}
                />
              </div>
              <div className="field md:col-6">
                <label className="mb-2" style={{ fontWeight: "bold" }}>
                  Expiration Date<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <Calendar
                  id="scanBasedExpirationDate"
                  value={scanBasedExpirationDate}
                  // onChange={(e) => setScanBasedExpirationDate(e.value)}
                  onChange={(e) => {
                    setScanBasedExpirationDate(e.value);
                    if (e.value) {
                      setScanError("");
                    }
                  }}
                  dateFormat="yy-mm-dd"
                  showIcon
                  minDate={new Date()}
                  placeholder="Select Date"
                />
                <br />
                {scanError && <small className="error">{scanError}</small>}
              </div>
            </>
          )}

          {settingTypeError && (
            <small className="error">{settingTypeError}</small>
          )}

          <div className="col-12 md:col-12">
            <div style={{ display: "grid", placeContent: "flex-end" }}>
              <Button
                loading={loading}
                className="custom-btn w100"
                label={"Save QR Setting"}
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrSetting;
